export default [
  {
    name: "trebleClef",
    type: "gltfModel",
    path: "models/treble.glb",
  },
  {
    name: "defaultFont",
    type: "font",
    path: "fonts/helvetiker_regular.typeface.json",
  },
  {
    name: "blackMatcap",
    type: "texture",
    path: "textures/matcaps/Black.png",
  },
  {
    name: "violinModel",
    type: "gltfModel",
    path: "models/violin.glb",
  },
  {
    name: "sectionFont",
    type: "font",
    path: "/fonts/Roboto Thin_Italic.json",
  },
  {
    name: "programModel",
    type: "gltfModel",
    path: "models/program.glb",
  },
  {
    name: "abletonModel",
    type: "gltfModel",
    path: "models/ableton.glb",
  },
  {
    name: "youtubeModel",
    type: "gltfModel",
    path: "models/youtube.glb",
  },
  {
    name: "postalgiaModel",
    type: "gltfModel",
    path: "models/postalgia.glb",
  },
  {
    name: "joboxModel",
    type: "gltfModel",
    path: "models/jobox.glb",
  },
  {
    name: "pixleeModel",
    type: "gltfModel",
    path: "models/pixlee.glb",
  },
  {
    name: "cSharpModel",
    type: "gltfModel",
    path: "models/cSharp.glb",
  },
  {
    name: "cPlusPlusModel",
    type: "gltfModel",
    path: "models/cPlusPlus.glb",
  },
  {
    name: "cModel",
    type: "gltfModel",
    path: "models/c.glb",
  },
  {
    name: "pythonModel",
    type: "gltfModel",
    path: "models/python.glb",
  },
  {
    name: "jsModel",
    type: "gltfModel",
    path: "models/js.glb",
  },
  {
    name: "cssModel",
    type: "gltfModel",
    path: "models/css.glb",
  },
  {
    name: "htmlModel",
    type: "gltfModel",
    path: "models/html.glb",
  },
  {
    name: "sqlModel",
    type: "gltfModel",
    path: "models/sql.glb",
  },
  {
    name: "reactModel",
    type: "gltfModel",
    path: "models/react.glb",
  },
  {
    name: "threejsModel",
    type: "gltfModel",
    path: "models/threejs.glb",
  },
  {
    name: "githubModel",
    type: "gltfModel",
    path: "models/github.glb",
  },
  {
    name: "javaModel",
    type: "gltfModel",
    path: "models/java.glb",
  },
  {
    name: "postgresModel",
    type: "gltfModel",
    path: "models/postgres.glb",
  },
  {
    name: "mongodbModel",
    type: "gltfModel",
    path: "models/mongodb.glb",
  },
  {
    name: "unityModel",
    type: "gltfModel",
    path: "models/unity.glb",
  },
  {
    name: "nodejsModel",
    type: "gltfModel",
    path: "models/nodejs.glb",
  },
  {
    name: "awsModel",
    type: "gltfModel",
    path: "models/aws.glb",
  },
  {
    name: "railsModel",
    type: "gltfModel",
    path: "models/rails.glb",
  },
  {
    name: "gmailModel",
    type: "gltfModel",
    path: "models/gmail.glb",
  },
  {
    name: "linkedinModel",
    type: "gltfModel",
    path: "models/linkedin.glb",
  },
  {
    name: "antichangeModel",
    type: "gltfModel",
    path: "models/antichange.glb",
  },
  {
    name: "xModel",
    type: "gltfModel",
    path: "models/x.glb",
  },
];
